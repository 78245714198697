<template>
  <div class="leet-basis-page">
    <div class="page-header">
      <img src="https://cdn.lnxall.com/news_banner.png" alt="" srcset="" />
      <div class="title-box">
        <div class="title-content animate__animated animate__backInLeft">
          <img class="leftimg" src="https://cdn.lnxall.com/left-icon.png" />
          <span>新闻中心</span>
          <img src="https://cdn.lnxall.com/right-icon.png" class="rightimg" />
        </div>
        <div class="sub-content animate__animated animate__backInLeft animate__delay-1s">
          <span>Company News</span>
        </div>
      </div>
    </div>
    <div class="page-body">
      <router-view class="leet-view" />
    </div>
  </div>
</template>

<script>
export default {
  name: "basis-page",
  components: {},
  data() {
    return {};
  },
  mounted() {},
  filters: {},
  created() {},
  methods: {},
  destroyed() {},
};
</script>
<style scoped lang="less">
.leet-basis-page {
  box-sizing: border-box;
  .page-header {
    height: 260px;
    box-sizing: border-box;
    position: relative;
    background-color: #0b0b0b;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    .title-box {
      position: absolute;
      left: 0;
      right: 0;
      top: 90px;
      box-sizing: border-box;
      .title-content {
        position: relative;
        color: #ffffff;
        font-size: 24px;
        font-family: PingFangSC-Medium;
        text-align: center;
        box-sizing: border-box;
        img {
          position: absolute;
        }
        .leftimg {
          width: 46px;
          height: 36px;
          left: 36px;
        }
        .rightimg {
          width: 46px;
          height: 36px;
          right: 36px;
        }
      }
      .sub-content {
        padding: 0 15px;
        position: relative;
        text-align: center;
        color: #ffffff;
        font-size: 18px;
        font-family: PingFangSC-Medium;
        line-height: 48px;
        margin-top: 10px;
        img {
          position: absolute;
        }
      }
    }
  }
  .page-body {
    box-sizing: border-box;
  }
}
</style>
